import {useInfiniteQuery as useInfiniteReactQuery} from '@tanstack/react-query';
import {accessObject, camelToSnake} from '@halp/util';
import {useGraphQLRequest} from './client';
import type {TypedDocumentNode} from '@graphql-typed-document-node/core';
import type {
	InfiniteData,
	QueryKey,
	UseInfiniteQueryOptions,
	UseInfiniteQueryResult,
} from '@tanstack/react-query';

export type SortOrderDirection = 'ASC' | 'DESC';

export interface InfiniteQueryVariables {
	offset?: number;
	limit?: number;
	orderBy?: string[];
	sortingOrder?: SortOrderDirection;
	search?: string;
}

export interface Options<Result, Variables>
	extends Omit<
			UseInfiniteQueryOptions<Result>,
			'refetchInterval' | 'queryKey' | 'initialPageParam' | 'getNextPageParam'
		>,
		InfiniteQueryVariables {
	variables?: Variables;
}

export function useInfiniteQuery<Result, Variables>(
	document: TypedDocumentNode<Result, Variables & InfiniteQueryVariables>,
	dataKey: string,
	options?: Options<Result, Variables>,
) {
	const request = useGraphQLRequest();
	const variables = (options?.variables ?? null) as Variables;
	const queryKey = [{document, variables, options}] as QueryKey;
	const limit = options?.limit ?? 15;
	const offset = options?.offset ?? 0;

	// TODO: @jtsmills handle this backend
	const orderBy = options?.orderBy?.map(camelToSnake);

	const queryFn = async ({pageParam}: {pageParam: number | unknown}) => {
		return request(document, {
			limit: limit,
			offset: pageParam,
			orderBy,
			sortingOrder: options?.sortingOrder,
			search: options?.search,
			...variables,
		});
	};

	return useInfiniteReactQuery({
		queryKey,
		queryFn,
		initialPageParam: offset,
		getNextPageParam: (_lastPage, pages) => {
			const d = accessObject(pages[pages.length - 1], dataKey);
			return Array.isArray(d) && d.length >= limit
				? pages.length * limit + offset
				: undefined;
		},
		...options,
	}) as UseInfiniteQueryResult<InfiniteData<Result, number>, unknown>;
}
