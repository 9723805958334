import {useCallback, useEffect, useMemo, useState} from 'react';
import {useRouter} from '@halp/ui/router';
import type {
	InfiniteQueryOptions,
	InfiniteQueryVariables,
	QueryOptions,
} from '@halp/api/graphql';
import {useInfiniteQuery, useQuery} from '@halp/api/graphql';
import type {SearchKey} from '@halp/util';
import {accessObject, stringFilter, useDebounce} from '@halp/util';
import {usePushQueryParams} from '../PushWithQueryParams/use-push-with-query-params';
import {DataTable} from './DataTable';
import {Filters} from './Filters';
import {Search} from './Search';
import style from './DataTable.module.css';
import {QueryTableProvider} from './QueryTableProvider';
import type {FilterGroup} from './Filters';
import type {Props as DataTableProps} from './DataTable';
import type {RowDataType, SortType} from 'rsuite-table';
import type {TypedDocumentNode} from '@graphql-typed-document-node/core';
import type {ReactNode} from 'react';

type TableProps<Data extends RowDataType> = Omit<
	DataTableProps<Data>,
	'totalPages' | 'data'
>;
type Data<Result> = Omit<Result[keyof Result], 'RootQueryType'>[];

type SortOrderDirection = 'ASC' | 'DESC';

interface BaseProps {
	page: number;
	onPageSelect: (page: number) => void;
	orderBy?: string;
	setOrderBy: (orderBy?: string) => void;
	sortingOrder?: SortOrderDirection;
	setSortingOrder: (sortOrder?: SortOrderDirection) => void;
	search: string;
	onSearch: (filter: string) => void;
	searchChildren?: ReactNode;
}

interface Query<Result, Variables> {
	query: TypedDocumentNode<Result, Variables>;
	dataKey: keyof Result;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	searchKeys?: SearchKey<any>[];
	limit?: number;
	infiniteQuery?: never;
	options?: QueryOptions<Result, Variables>;
	pushSearchQueryParams?: boolean;
	searchChildren?: ReactNode;
}

type QueryProps<Result, Variables> = Query<Result, Variables> &
	TableProps<Data<Result>[0]>;

interface InfiniteQuery<Result, Variables> {
	query?: never;
	dataKey: string;
	infiniteQuery: TypedDocumentNode<Result, Variables & InfiniteQueryVariables>;
	enableSearch?: boolean;
	options?: InfiniteQueryOptions<Result, Variables>;
	pushSearchQueryParams?: boolean;
	debounceTime?: number;
	searchChildren?: ReactNode;
	children?: ReactNode;
}

type InfiniteQueryProps<Result, Variables> = InfiniteQuery<Result, Variables> &
	TableProps<Data<Result>[0]>;

export type Props<Result, Variables> =
	| QueryProps<Result, Variables>
	| InfiniteQueryProps<Result, Variables>;

function SimpleQueryTable<Result, Variables>({
	query,
	dataKey,
	options,
	limit,
	page,
	onPageSelect,
	orderBy,
	setOrderBy,
	sortingOrder,
	setSortingOrder,
	search,
	onSearch,
	searchKeys,
	searchChildren,
	...props
}: QueryProps<Result, Variables> & BaseProps) {
	const {data, isLoading} = useQuery(query, options);
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const [filters, setFilters] = useState<any>({});

	const arr = useMemo((): Data<Result> => {
		const d = data?.[dataKey];
		return Array.isArray(d) ? (d as unknown as Data<Result>) : [];
	}, [data, dataKey]);

	const filterGroups = useMemo(() => {
		return (
			props.columns
				?.map((column) => {
					if (column.filterable && data) {
						const options = new Set(
							// eslint-disable-next-line @typescript-eslint/no-explicit-any
							arr.map((obj: any) => accessObject(obj, column.key)),
						);

						return {
							key: column.key,
							label: column.label,
							options: Array.from(options).sort(),
						};
					}
					return null;
				})
				.filter((group): group is FilterGroup => group != null) ?? []
		);
	}, [arr, props.columns, data]);

	const filteredData = useMemo(() => {
		return arr.slice().filter((row) => {
			for (let filter of Object.keys(filters)) {
				if (accessObject(row, filter) !== filters[filter]) return false;
			}
			return true;
		});
	}, [arr, filters]);

	const searchData = useMemo(
		() =>
			searchKeys
				? (stringFilter(
						// eslint-disable-next-line @typescript-eslint/no-explicit-any
						filteredData as any,
						searchKeys,
						search,
					) as unknown as Data<Result>)
				: filteredData,
		[filteredData, searchKeys, search],
	);

	const sortedData = useMemo(() => {
		const reverse = sortingOrder === 'ASC' ? 1 : -1;
		if (!sortingOrder || !orderBy) {
			return searchData;
		}
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		return searchData?.slice().sort((a: any, b: any) => {
			const valA = `${accessObject(a, orderBy)}`;
			const valB = `${accessObject(b, orderBy)}`;
			return (
				valA.localeCompare(valB, navigator.languages[0] || navigator.language, {
					numeric: true,
					ignorePunctuation: true,
				}) * reverse
			);
		});
	}, [searchData, sortingOrder, orderBy]);

	const limitedData = useMemo(() => {
		return limit
			? sortedData.slice(page * limit, (page + 1) * limit)
			: sortedData;
	}, [sortedData, page, limit]);

	const onSortColumn = useCallback(
		(dataKey: string, dir?: SortType) => {
			onPageSelect(0);
			setOrderBy(dir ? dataKey : undefined);
			setSortingOrder(
				dir === 'asc' ? 'ASC' : dir === 'desc' ? 'DESC' : undefined,
			);
		},
		[setOrderBy, setSortingOrder, onPageSelect],
	);

	return (
		<div className={style.TableContainer}>
			{searchKeys ? (
				<Search value={search} onChange={onSearch}>
					{searchChildren}
				</Search>
			) : null}
			{filterGroups.length > 0 ? (
				<>
					<Filters
						filters={filters}
						setFilters={setFilters}
						filterGroups={filterGroups}
					/>
					<hr />
				</>
			) : null}
			<DataTable
				{...(props as DataTableProps<Data<Result>[0]>)}
				data={limitedData}
				loading={isLoading}
				page={page}
				onPageSelect={onPageSelect}
				sortColumn={orderBy}
				onSortColumn={onSortColumn}
				totalPages={limit ? sortedData.length / limit : undefined}
			/>
		</div>
	);
}

function InfiniteQueryTable<Result, Variables>({
	infiniteQuery,
	dataKey,
	options,
	page,
	onPageSelect,
	orderBy,
	setOrderBy,
	sortingOrder,
	setSortingOrder,
	search,
	onSearch,
	enableSearch,
	debounceTime = 320,
	searchChildren,
	children,
	...props
}: InfiniteQueryProps<Result, Variables> & BaseProps) {
	const debouncedSearch = useDebounce(search, debounceTime);
	const {data, isLoading, fetchNextPage, hasNextPage} = useInfiniteQuery(
		infiniteQuery,
		dataKey,
		{
			...options,
			orderBy: orderBy ? [orderBy] : undefined,
			sortingOrder,
			search: debouncedSearch,
		},
	);

	const onSortColumn = useCallback(
		(dataKey: string, dir?: SortType) => {
			onPageSelect(0);
			setOrderBy(dir ? dataKey : undefined);
			setSortingOrder(
				dir === 'asc' ? 'ASC' : dir === 'desc' ? 'DESC' : undefined,
			);
		},
		[setOrderBy, setSortingOrder, onPageSelect],
	);

	const pageSelect = useCallback(
		async (newPage: number) => {
			if (newPage > page) {
				await fetchNextPage();
			}
			onPageSelect(newPage);
		},
		[page, fetchNextPage, onPageSelect],
	);

	const arr = useMemo((): Data<Result> => {
		const d = accessObject(data?.pages[page], dataKey);
		return Array.isArray(d) ? (d as unknown as Data<Result>) : [];
	}, [data, page, dataKey]);

	const pages = data?.pages.length ?? 1;

	const operationName =
		infiniteQuery.definitions[0].kind === 'OperationDefinition'
			? infiniteQuery.definitions[0].name?.value
			: undefined;

	return (
		<div className={style.TableContainer}>
			<QueryTableProvider
				/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
				data={data?.pages as any[]}
				isLoading={isLoading}
				operationName={operationName}
			>
				{children}
			</QueryTableProvider>
			{enableSearch ? (
				<Search value={search} onChange={onSearch}>
					{searchChildren}
				</Search>
			) : null}
			<DataTable
				{...(props as DataTableProps<Data<Result>[0]>)}
				data={arr}
				loading={isLoading}
				page={page}
				onPageSelect={pageSelect}
				sortColumn={orderBy}
				onSortColumn={onSortColumn}
				totalPages={hasNextPage ? pages + 1 : pages}
			/>
		</div>
	);
}

export function QueryTable<Result, Variables>({
	page: initialPage = 0,
	onPageSelect,
	sortColumn,
	sortType,
	pushSearchQueryParams,
	...props
}: Props<Result, Variables>) {
	const router = useRouter();
	const pushQueryParams = usePushQueryParams();
	const queryParamKey = `search_${String(props.dataKey)}`;
	const {[queryParamKey]: searchQuery} = router.query;
	const [page, setPage] = useState(initialPage);
	const [search, setSearch] = useState(
		searchQuery && !Array.isArray(searchQuery) ? searchQuery : '',
	);
	const [orderBy, setOrderBy] = useState<string | undefined>(
		sortColumn ?? 'id',
	);
	const [sortingOrder, setSortingOrder] = useState<
		SortOrderDirection | undefined
	>(sortType !== 'desc' ? 'ASC' : 'DESC');

	useEffect(() => {
		setPage(0);
	}, [search, orderBy, sortingOrder]);

	const pageSelect = useCallback(
		(page: number) => {
			onPageSelect?.(page);
			setPage(page);
		},
		[onPageSelect, setPage],
	);

	function onSearch(search: string) {
		setSearch(search);
		if (pushSearchQueryParams) {
			pushQueryParams({[queryParamKey]: search});
		}
	}

	if (props.query) {
		return (
			<SimpleQueryTable
				page={page}
				onPageSelect={pageSelect}
				search={search}
				onSearch={onSearch}
				orderBy={orderBy}
				setOrderBy={setOrderBy}
				sortingOrder={sortingOrder}
				setSortingOrder={setSortingOrder}
				{...props}
			/>
		);
	}
	return (
		<InfiniteQueryTable
			page={page}
			onPageSelect={pageSelect}
			search={search}
			onSearch={onSearch}
			orderBy={orderBy}
			setOrderBy={setOrderBy}
			sortingOrder={sortingOrder}
			setSortingOrder={setSortingOrder}
			{...props}
		/>
	);
}
