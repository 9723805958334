import {Dropdown, Link} from '@halp/ui';
import style from './DataTable.module.css';

interface Props {
	filterGroups: FilterGroup[];
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	filters: Record<string, any>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	setFilters: (filters: Record<string, any>) => void;
}

export interface FilterGroup {
	key: string;
	label: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	options: any[];
}

export function Filters({filterGroups, filters, setFilters}: Props) {
	return (
		<div className={style.Filters}>
			{filterGroups.map((filter) => {
				if (filter == null) return null;

				return (
					<div key={filter?.key}>
						{filter?.label}:{' '}
						<Dropdown
							toggle={filters[filter.key] ? filters[filter.key] : 'All'}
						>
							<ul>
								<li>
									<Link
										onClick={() => {
											const newFilters = {...filters};
											delete newFilters[filter.key];
											setFilters(newFilters);
										}}
									>
										All
									</Link>
								</li>
								{filter.options.map((value) => {
									return (
										<li key={value}>
											<Link
												onClick={() => {
													const newFilters = {...filters};
													newFilters[filter.key] = value;
													setFilters(newFilters);
												}}
											>
												{value}
											</Link>
										</li>
									);
								})}
							</ul>
						</Dropdown>
					</div>
				);
			})}
		</div>
	);
}
