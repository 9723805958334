import {useMemo} from 'react';
import {faChevronLeft, faChevronRight} from '@fortawesome/free-solid-svg-icons';
import {Icon} from '../Icon';
import {Button} from '../Button';

export interface Props {
	page: number;
	totalPages: number;
	onPageSelect?: (page: number) => void;
}

export function Pagination({page, totalPages, onPageSelect}: Props) {
	const previousButtons = useMemo(() => {
		let buttons = [];

		for (let i = Math.max(0, page - 3); i < page; i++) {
			buttons.push(
				<Button
					thin
					color="secondary"
					key={i}
					onClick={() => onPageSelect?.(i)}
				>
					{i + 1}
				</Button>,
			);
		}

		return buttons;
	}, [page, onPageSelect]);

	const nextButtons = useMemo(() => {
		let buttons = [];

		for (let i = page + 1; i < Math.min(totalPages, page + 3); i++) {
			buttons.push(
				<Button
					thin
					color="secondary"
					key={i}
					onClick={() => onPageSelect?.(i)}
				>
					{i + 1}
				</Button>,
			);
		}

		return buttons;
	}, [page, totalPages, onPageSelect]);

	return (
		<div>
			<Button
				thin
				color="secondary"
				onClick={() => onPageSelect?.(page - 1)}
				disabled={page === 0}
			>
				<Icon icon={faChevronLeft} />
			</Button>
			{previousButtons}
			<Button thin>{page + 1}</Button>
			{nextButtons}
			<Button
				thin
				color="secondary"
				onClick={() => onPageSelect?.(page + 1)}
				disabled={page === totalPages - 1}
			>
				<Icon icon={faChevronRight} />
			</Button>
		</div>
	);
}
