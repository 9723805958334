import {useMemo} from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type SearchKey<T> = keyof T | {[key: string]: SearchKey<any>[]};

function filterKey<T>(datum: T, searchKeys: SearchKey<T>[], search: string) {
	for (const searchKey of searchKeys) {
		if (typeof searchKey === 'string') {
			const upper = search.toUpperCase();
			const value = datum[searchKey];
			if (
				value &&
				typeof value === 'string' &&
				value.toUpperCase().indexOf(upper) > -1
			) {
				return true;
			}
		} else {
			for (const nestedKey of Object.keys(searchKey)) {
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				const nestedObj = (datum as any)[nestedKey];
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				const nestedSearchKeys = (searchKey as any)[nestedKey];
				if (filterKey(nestedObj, nestedSearchKeys, search)) {
					return true;
				}
			}
		}
	}
	return false;
}

export function stringFilter<T>(
	data: T[],
	searchKeys: SearchKey<T>[],
	search?: string,
) {
	if (search == null || search === '') {
		return data.slice();
	}

	const results = data
		.slice()
		.filter((datum) => filterKey(datum, searchKeys, search));

	return results;
}

export function useStringFilter<T>(
	data: T[],
	searchKeys: SearchKey<T>[],
	search?: string,
) {
	return useMemo(() => {
		return {results: stringFilter(data, searchKeys, search)};
	}, [data, searchKeys, search]);
}
